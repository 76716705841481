import React, { useState } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import InnerBanner from "../../Components/InnerBanner";
import CalltoAction from "../../Components/CalltoAction";
import TopBanner from "../../Components/TopBanner";

const Partners = () => {
  const [error, setError] = useState(false);

  const partnerslogo = [
    { logo: "assets/logo/1.png", url: "https://web3preneur.com/" },
    { logo: "assets/logo/3.png", url: "https://nufi.vc/" },
    { logo: "assets/logo/4.png", url: "#" },
    { logo: "assets/logo/5.png", url: "https://masterverses.com/" },
    { logo: "assets/logo/6.png", url: "https://cypherchain.org/" },
    { logo: "assets/logo/7.png", url: "#" },
    { logo: "assets/logo/8.png", url: "https://ecotrader.io/" },
    { logo: "assets/logo/9.png", url: "https://www.blockting.com/" },
    { logo: "assets/logo/10.png", url: "https://braands.io/" },
    { logo: "assets/logo/11.png", url: "https://www.wowtalkies.com/" },
    { logo: "assets/logo/12.png", url: "https://www.shipfinex.com/" },
    { logo: "assets/logo/13.png", url: "https://web3.vacations/" },
    { logo: "assets/logo/15.png", url: "http://www.rapidz.io/" },
    { logo: "assets/logo/16.png", url: "http://www.blockon.biz/" },
  ];

  if (error) {
    return (
      <>
        <Header />
        <main>
          <InnerBanner
            sectionname="Connect with Nuchain"
            heading="Partners"
            subheading="Reach out to us in the way that works best for you"
            imageurl="assets/bannerimages/Partners-min.png"
          />
          <div className="container-xxl">
            <h1>Something went wrong</h1>
          </div>
        </main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <main>
        <InnerBanner
          sectionname="Connect with Nuchain"
          heading="Partners"
          subheading="Reach out to us in the way that works best for you"
          imageurl="assets/bannerimages/Partners-min.png"
        />

        <TopBanner content="NuChain thrives on a collaborative spirit. Our ecosystem partners are the backbone of our innovation. This includes businesses of all sizes, talented developers, and forward-thinking organizations who share our vision for the transformative power of Blockchain technology. Together, we're building groundbreaking solutions that push the boundaries of what's possible on the NuChain platform." />

        <section className="section-spacing-design">
          <div className="container-xxl">
            <div className="row g-3 d-flex justify-content-center align-items-center">
              {partnerslogo.map((partner, index) => (
                <div key={index} className="col col-md-2">
                  <a
                    href={partner.url}
                    target={partner.url !== "#" ? "_blank" : ""}
                    rel="noopener noreferrer"
                  >
                    <div className="d-flex justify-content-center align-items-center logo-image-box">
                      <div
                        className="image-container"
                        style={{ backgroundImage: `url(${partner.logo})` }}
                      />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
        <CalltoAction cta="Build with NuChain. Shape the future." />
      </main>
      <Footer />
    </>
  );
};

export default Partners;
